.topic-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .topic-title {
    color: #333;
    font-size: 2.5em;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .topic-description {
    color: #666;
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .question-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .question-text {
    font-size: 1.3em;
    color: #444;
    margin-bottom: 20px;
  }
  
  .answer-list {
    list-style-type: none;
    padding: 0;
  }
  
  .answer-item {
    margin-bottom: 10px;
  }
  
  .answer-button {
    width: 100%;
    padding: 10px 15px;
    font-size: 1em;
    color: #333;
    background-color: #e0e0e0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .answer-button:hover {
    background-color: #d0d0d0;
  }
  
  .answer-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .feedback {
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .feedback.correct {
    background-color: #d4edda;
    color: #155724;
  }
  
  .feedback.incorrect {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .next-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .next-button:hover {
    background-color: #0056b3;
  }